import React, { useState } from "react";
import theme from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";
import {
  faArrowUpRightFromSquare,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

export default function SubStoreInviteTemp({ shopData }) {
  const [loading, setLoading] = useState({}); // Track loading state per row
  const [error, setError] = useState(null);
  const accessStoreToken = localStorage.getItem("accessStoreToken");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleResend = async (storeid) => {
    setLoading((prev) => ({ ...prev, [storeid]: true })); // Set loading for specific row
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_NEGO_URL}resend-hubstore-invite`,
        { verifyid: storeid },
        {
          headers: {
            Authorization: `Bearer ${accessStoreToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      //   if(response.data.statuscode===201){
      setShowSnackbar(true);
      //   }
      setError(null); // Clear previous errors
    } catch (err) {
      setError(err.response?.data?.message || "Failed to resend invite");
    } finally {
      setLoading((prev) => ({ ...prev, [storeid]: false })); // Reset loading for specific row
    }
  };
  const handleSetStoreDetails = () => {
    navigate("/subStore");
  };
  return (
    <div className="col-md-12 mb-1">
      <div className="mt-1 rounded-3" style={{ backgroundColor: "#eeeeee" }}>
        <table className="table mb-0">
          <thead
            className="table-light"
            style={{
              position: "sticky",
              top: 0,
            }}
          >
            <tr>
              <th
                className="text-left"
                style={{ color: theme.palette.blue.dark }}
              >
                No
              </th>
              <th
                className="text-left"
                style={{ color: theme.palette.blue.dark }}
              >
                Title
              </th>
              <th
                className="text-center"
                style={{ color: theme.palette.blue.dark }}
              >
                Email
              </th>
              <th
                className="text-center"
                style={{ color: theme.palette.blue.dark }}
              >
                Status
              </th>
              <th
                className="text-center"
                style={{ color: theme.palette.blue.dark }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {shopData &&
              shopData.map((it, index) => (
                <tr key={it._id}>
                  <td style={{ fontSize: "14px" }}>
                    <span className="badge rounded-pill bg-label-blue text-black">
                      {index + 1}
                    </span>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    <div className="d-flex align-items-center">
                      <div className="font-weight-bold">{it.title}</div>
                    </div>
                  </td>
                  <td className="text-center" style={{ fontSize: "14px" }}>
                    <div>{it.email}</div>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    <div
                      className="d-block order-actions text-center"
                      style={{
                        color: it.status === "INVITED" ? "red" : "green",
                      }}
                    >
                      {it.status}
                    </div>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    {it.status === "INVITED" ? (
                      <div className="d-block order-actions text-center">
                        <button
                          className="btn btn-sm hoveradd"
                          onClick={() => handleResend(it._id)} // Pass function reference
                          style={{
                            backgroundColor: theme.palette.blue.dark,
                            color: "white",
                          }}
                          disabled={loading[it._id]} // Disable only the current button
                        >
                          {loading[it._id] ? (
                            <>
                              <FontAwesomeIcon
                                icon={faSpinner}
                                className="me-1"
                                spin
                              />{" "}
                              Resend..
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={faTelegram}
                                className="me-1"
                              />{" "}
                              Resend
                            </>
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000} // Close after 3 seconds
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "#388e3c", // Change background color to dark green
            color: "white", // Ensure text is readable on dark green
          }}
        >
          Invitation sent to your email. Please accept it.
        </Alert>
      </Snackbar>
    </div>
  );
}
