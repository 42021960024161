import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Login from './login/Login';
import Otp from './login/Otp';
import Home from './home/Home'
import  Nav  from 'react-bootstrap/Nav';
import theme from './theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Subhome from './subHome/Subhome';

function Header(){
  const location = useLocation();
  const handleLogout=()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('storeDetails')
    console.log('removed')
  }

  if (location.pathname === "/login" || location.pathname === '/otp') {
    return null;
  }
  return(
    <nav style={{backgroundColor:theme.palette.secondary.lighter}}>
    <header className="d-flex align-items-center justify-content-center" style={{height: '50px', backgroundColor:theme.palette.blue.dark}}>
<div className="d-flex justify-content-between align-items-center w-100 px-4">
  {/* Menu Icon */}
  <Nav>
    <a href='/'>
    <img src='/nego/logo-img.png' style={{width:'9rem'}}/>
    </a>
  </Nav>
  <Nav className="pointer ms-3">
    <span className='text-white fs-4'>SUB & HUB MANAGEMENT</span>
  </Nav>
  <Nav  className='pointer'>
    <a href='/login' className='text-decoration-none'>
      <FontAwesomeIcon className='text-white me-2' icon={faArrowRightFromBracket}/>
    <span className='text-white' onClick={handleLogout}>Logout</span>
    </a>
  </Nav>
  {/* <Nav><span>logout</span></Nav> */}
</div>
</header>
</nav>
  )
}

function App() {
 
  return (
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/otp' element={<Otp/>} />
        <Route path='/' element={<Home/>} />
        <Route path='/subStore' element={<Subhome/>} />
      </Routes>
      
      </BrowserRouter>
  );
}

export default App;
