import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import HubStoretemp from "./HubStoreTemp";
import theme from "../theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { refreshAccessToken } from "../api/SubStore";
import HubStoreinviteTemp from "./HubStoreinviteTemp";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HubStoreTab() {
  const [value, setValue] = React.useState(0);
  const [substore, setSubstore] = React.useState([]);
  const [shops, setShops] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  //   const accessStoreToken=localStorage.getItem('accessStoreToken');
  const token = localStorage.getItem("accessToken");
  const [accessStoreToken, setStoreaccessToken] = React.useState(
    localStorage.getItem("")
  );
  const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    async function fetchAuthStore() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_NEGO_URL}auth-store`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.statusCode === 200) {
          const newAccessToken = data.results.accessToken;
          localStorage.setItem("accessStoreToken", newAccessToken);
          localStorage.setItem("refreshStoreToken", data.results.refreshToken);
          setStoreaccessToken(newAccessToken);

          console.log("Tokens set successfully:", data);
        } else if (data.statusCode === 401) {
          throw new Error("Unauthorized");
        } else if (data.statusCode === 404) {
          localStorage.removeItem("accessStoreToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshStoreToken");
          localStorage.removeItem("refreshToken");
          navigate("/login");
        }
      } catch (error) {
        if (error.message == "Unauthorized") {
          token = await refreshAccessToken();
          if (token) {
            fetchAuthStore();
          } else {
            localStorage.removeItem("accessStoreToken");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshStoreToken");
            localStorage.removeItem("refreshToken");
            navigate("/login");
          }

          setError("Failed to fetch auth store tokens");
          console.error("Error fetching auth store:", error);
        } else {
          console.error("Error fetching products:", error);
          localStorage.removeItem("accessStoreToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshStoreToken");
          localStorage.removeItem("refreshToken");
          navigate("/login");
        }
      }
    }
    async function fetchShops() {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_NEGO_URL}list-hubstore`,
        {subid:storeDetails?._id},
        {
          headers: {
            Authorization: `Bearer ${accessStoreToken}`,
          },
        });
        setShops(response.data.results);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
    async function fetchSubstoreInvite() {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_NEGO_URL}list-hubstore-invite`, 
        {subid:storeDetails?._id},
        {
          headers: {
            Authorization: `Bearer ${accessStoreToken}`,
          },
        });
        setSubstore(response.data.results);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    if (!accessStoreToken) {
      fetchAuthStore();
    } else {
      fetchShops();
      fetchSubstoreInvite();
    }
  }, [accessStoreToken, token]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.blue.dark, // Change to your desired underline color
            },
          }}
          sx={{
            "& .Mui-selected": {
              color: `${theme.palette.blue.dark} !important`, // Selected label color
              fontWeight: "bold", // Optional: Make selected label bold
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.blue.dark, // Ensures underline matches selected label color
            },
          }}
        >
          <Tab label="All Stores" {...a11yProps(0)} />
          <Tab label="Active stores" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <HubStoreinviteTemp shopData={substore} key={substore._id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <HubStoretemp shopData={shops} key={shops._id} />
      </CustomTabPanel>
    </Box>
  );
}
