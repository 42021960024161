import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    blue: {
      light: '#e3f2fd', //50
      lighter:'#bbdefb', //100
      lightdark:'#666cff',
      main: '#1976d2', //700
      dark: '#0a075c', //900 //#004a9d
      contrastText: '#42a5f5',//400
    },
    secondary: {
      light: '#fafafa',//50
      lighter:'#f5f5f5',//100
      lightmain:'#e0e0e0',//300
      main: '#bdbdbd',//400
      dark: '#616161',//700
      contrastText: '#000',
    },
  },
});

export default theme;