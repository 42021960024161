import React from "react";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

export default function SubStoretemp({ shopData }) {
  const navigate = useNavigate();

  const handleSetStoreDetails = (id) => {
    const storeDetails = shopData.find((item) => item._id === id);

    if (storeDetails) {
      localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
    }

    console.log("Stored details:", storeDetails);
    navigate("/subStore");
  };

  return (
    <div className="col-md-12 mb-1">
      <div className="mt-1 rounded-3" style={{ backgroundColor: "#eeeeee" }}>
        <table className="table mb-0">
          <thead
            className="table-light"
            style={{
              position: "sticky",
              top: 0,
            }}
          >
            <tr>
              <th className="text-left" style={{ color: theme.palette.blue.dark }}>
                No
              </th>
              <th className="text-left" style={{ color: theme.palette.blue.dark }}>
                Title
              </th>
              <th className="text-center" style={{ color: theme.palette.blue.dark }}>
                Email
              </th>
              <th className="text-center" style={{ color: theme.palette.blue.dark }}>
                Store Type
              </th>
              <th className="text-center" style={{ color: theme.palette.blue.dark }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {shopData &&
              shopData.map((it, index) => (
                <tr key={it._id}>
                  <td style={{ fontSize: "14px" }}>
                    <span className="badge rounded-pill bg-label-blue text-black">
                      {index + 1}
                    </span>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    <div className="d-flex align-items-center">
                      <div className="font-weight-bold">{it.title}</div>
                    </div>
                  </td>
                  <td className="text-center" style={{ fontSize: "14px" }}>
                    <div>{it.email}</div>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    <div
                      className="d-block order-actions text-center"
                      style={{ color: "#28a745" }}
                    >
                      {it.stype}
                    </div>
                  </td>
                  <td style={{ fontSize: "14px" }}>
                    <div className="d-block order-actions text-center">
                      <button
                        className="btn btn-sm hoveradd"
                        onClick={() => handleSetStoreDetails(it._id)}
                        style={{
                          backgroundColor: theme.palette.blue.dark,
                          color: "white",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          className="me-1"
                        />{" "}
                        Enter
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
