import { faArrowRightLong, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import theme from '../theme';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Otp() {
    const [width, setWidth] = useState(null);
    const [otp, setOtp] = useState(''); 
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false); // Track loading state
    const navigate=useNavigate();
    const verifyToken=localStorage.getItem('verifyToken')
    console.log('verifyToken',verifyToken)
  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width === null) {
    return null;
  }
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_LOGIN}auth-email-otp`,
        {
          verifycode: otp, // Use email as username
        },
        {
          headers: {
            Authorization: `Bearer ${verifyToken}`, // Add the token here
          },
        }
      );

      const accessToken = response.data.results.accessToken; // Extract accessToken from response
      const refreshToken = response.data.results.refreshToken;
      console.log('loginRes',response.data)
      // Save the accessToken in localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem('refreshToken',refreshToken);
      console.log("login",verifyToken);
      navigate("/");
    } catch (error) {
      // Handle error (e.g., incorrect credentials)
      console.error("Login failed:", error.response.data.message);
      setError(error.response.data.message); // Show error message to user
    }
    finally {
      setLoading(false); // Stop loading after API request is complete
    }
  };
  return (
    <div
    className="container"
    style={{ height: "700px", width: width < 768 ? "95%" : "75%" }}
  >
    <div
      className="row justify-content-center"
      style={{ paddingTop: "150px" }}
    >
      <div
        className="col-md-6 col-sm-12 py-4 mb-2"
        style={{ backgroundColor: 'white', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",borderRadius:'10px' }}
      >
        <div className="text-center pb-1">
          <img src='/nego/Icon_NegoBill_logo_V2.png' className='logo'/>
        </div>
        <div className="text-center pb-1">
          <span className="fw-semibold" style={{fontSize:'20px',color:theme.palette.blue.dark}}>OTP Verification</span>
        </div>
        <div className="text-center">
          <span
            className="fw-semibold "
            style={{ fontSize: "14px", color: theme.palette.blue.dark }}
          >
            We have send the otp on Entered Email
          </span>
        </div>
        {/* {error && <div className="text-danger text-center">{error}</div>}{" "} */}
        {/* Show error if login fails */}
        <form
          onSubmit={handleLogin}
          className="d-flex flex-column align-items-center py-2"
          style={{ width: "70%", margin: "0 auto" }}
        >
          <input
            type="text"
            placeholder="Enter Your One-time Otp"
            className="hoverborder"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{
              width: "100%", // Make the input take the full width of its parent
              padding: "15px", // Add some padding for better appearance
              borderRadius: "10px", // Add rounded corners
              border: "none", // Light gray border
              marginBottom: "15px", // Space between inputs
              backgroundColor: theme.palette.secondary.lightmain,
              color: theme.palette.secondary.dark,
            }}
          />
          {error && <div className='text-danger'>{error}</div>}
          <button
            type="submit"
            className='hoveradd'
            style={{
              width: "100%", // Make the button take the full width of its parent
              padding: "10px", // Add some padding for better appearance
              borderRadius: "10px", // Add rounded corners
              border: "none", // Remove default border
              backgroundColor: theme.palette.blue.dark, // Bootstrap primary color
              color: "white", // Text color
              cursor: "pointer", // Change cursor on hover
              fontSize: "16px", // Increase font size for better readability
            }}
          >
             {loading ? (
                <>
                process.. <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;
              </>
              ) : (
                <> Next <FontAwesomeIcon icon={faArrowRightLong} /></>
              )}
           
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
