import React ,{ useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshStoreToken");
  
  if (refreshToken) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_NEGO_URL}create-token`,
        { headers: {
          Authorization: `Bearer ${refreshToken}`,
        }, }
      );
      
      const newAccessToken = response.data.results.accessToken;
      localStorage.setItem("accessStoreToken", newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }
  return null;
};




  