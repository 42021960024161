import React from "react";
import theme from "../theme";

export default function HubStoretemp({ shopData }) {
  return (
    <>
      <div className="col-md-12 mb-1">
        <div className=" mt-1 rounded-3" style={{ backgroundColor: "#eeeeee" }}>
          <table className="table  mb-0">
            <thead
              className="table-light"
              style={{
                // backgroundColor: theme.palette.secondary.lighter,
                position: "sticky",
                top: 0,
              }}
            >
              <tr>
                <th
                  className="text-left"
                  style={{ color: theme.palette.blue.dark }}
                >
                  No
                </th>
                <th
                  className="text-left"
                  style={{ color: theme.palette.blue.dark }}
                >
                  Title
                </th>
                <th
                  className="text-center"
                  style={{ color: theme.palette.blue.dark }}
                >
                  Email
                </th>
                <th
                  className="text-center"
                  style={{ color: theme.palette.blue.dark }}
                >
                  Status
                </th>
                <th
                  className="text-center"
                  style={{ color: theme.palette.blue.dark }}
                >
                  Store Type
                </th>
                {/* <th className="text-center">Action</th> */}
              </tr>
            </thead>
            {shopData &&
              shopData.map((it, index) => (
                <tbody key={it._id}>
                  <tr>
                    <td style={{ fontSize: "14px" }}>
                      <span className="badge rounded-pill bg-label-blue text-black">
                        {index + 1}
                      </span>
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      <div className="d-flex align-items-center">
                        <div className="font-weight-bold">{it.title}</div>
                      </div>
                    </td>
                    <td className="text-center" style={{ fontSize: "14px" }}>
                      <div>{it.email}</div>
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      <div
                        className="d-block order-actions text-center"
                        style={{ color: "#28a745" }}
                      >
                        {it.status}
                      </div>
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      <div className="d-block order-actions text-center">
                        {it.stype}
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>
    </>
  );
}
