import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material"; // MUI components
import { refreshAccessToken } from "../api/SubStore";
import { useNavigate } from "react-router-dom";
import theme from "../theme";
import HubStoreTab from "./HubStoreTab";

export default function Subhome() {
  const [shops, setShops] = useState([]);
  const [substore, setSubstore] = useState([]);
  const [ShopDetails, setShopDetatils] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false); // For controlling the modal visibility
  const [showSnackbar, setShowSnackbar] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [accessStoreToken, setStoreaccessToken] = useState(
    localStorage.getItem("")
  );
  const storeDetails = JSON.parse(localStorage.getItem("storeDetails"));
  console.log("selectedstore",storeDetails)
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAuthStore() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_NEGO_URL}auth-store`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.statusCode === 200) {
          const newAccessToken = data.results.accessToken;
          localStorage.setItem("accessStoreToken", newAccessToken);
          localStorage.setItem("refreshStoreToken", data.results.refreshToken);
          setStoreaccessToken(newAccessToken);

          // console.log("Tokens set successfully:", data);
        } else if (data.statusCode === 401) {
          throw new Error("Unauthorized");
        } else if (data.statusCode === 404) {
          localStorage.removeItem("accessStoreToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshStoreToken");
          localStorage.removeItem("refreshToken");
          navigate("/login");
        }
      } catch (error) {
        if (error.message == "Unauthorized") {
          token = await refreshAccessToken();
          if (token) {
            fetchAuthStore();
          } else {
            localStorage.removeItem("accessStoreToken");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshStoreToken");
            localStorage.removeItem("refreshToken");
            navigate("/login");
          }

          setError("Failed to fetch auth store tokens");
          console.error("Error fetching auth store:", error);
        } else {
          console.error("Error fetching products:", error);
          localStorage.removeItem("accessStoreToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshStoreToken");
          localStorage.removeItem("refreshToken");
          navigate("/login");
        }
      }
    }

    async function fetchShopdetails() {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_NEGO_URL}list-hubstore`, 
        {subid:storeDetails?._id},
        {
          headers: {
            Authorization: `Bearer ${accessStoreToken}`,
          },
        });
        if (response.data.statusCode===200) {
          setShopDetatils(response.data.results);
          setLoading(false);
        } else {
          navigate("/login");
          localStorage.removeItem('storeDetails')
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    if (!accessStoreToken) {
      fetchAuthStore();
    } else {
      fetchShopdetails();
    }
  }, [accessStoreToken, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_NEGO_URL}add-hubstore`,
        { title: title, email: email,subid:storeDetails?._id},
        {
          headers: {
            Authorization: `Bearer ${accessStoreToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      //   setResponseMessage("Substore added successfully");
      setError(null);
      setTitle(""); // Reset title field
      setEmail("");
      setShowModal(false); // Close the modal on success
      setShops([...shops, response.data]); // Update shops list with new entry
      setLoading(false);
      setError(false);
      setShowSnackbar(true);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add substore");
      setLoading(false);
    }
  };
  const handleBack=()=>{
    localStorage.removeItem('storeDetails')
    navigate('/')
  }
  //   const handlelogout = () => {
  //     localStorage.removeItem("accessStoreToken");
  //     localStorage.removeItem("accessToken");
  //     localStorage.removeItem("refreshStoreToken");
  //     localStorage.removeItem("refreshToken");
  //     navigate("/login");
  //   };
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: theme.palette.secondary.lighter,
        minHeight: "678px",
      }}
    >
      {/* <div>
        <button className="btn btn-sm " onClick={handlelogout}>
          logout
        </button>
      </div> */}
      <div className="d-flex justify-content-between pt-2">
        <div>
          <button
            className="btn btn-sm hoveradd"
            style={{ backgroundColor: theme.palette.blue.dark, color: "white" }}
            onClick={handleBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm hoveradd"
            style={{ backgroundColor: theme.palette.blue.dark, color: "white" }}
            onClick={() => setShowModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Hub
          </button>
        </div>
      </div>
      <div className="row py-2">
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full viewport height for centering
              width: "100%", // Ensure it spans the full width
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <>
            <div className="col-3">
              <div
                className="store-details-container py-2 px-3"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)", // Subtle shadow for depth
                  // paddingTop: "7px",

                  //   maxWidth: "400px", // Limit width for better structure
                  //   margin: "20px auto", // Center the card horizontally
                }}
              >
                {/* <div
                  style={{
                    borderBottom: `2px solid ${theme.palette.blue.dark}`, // Divider for header
                    paddingBottom: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#0a075c",
                      textTransform: "uppercase", // Add emphasis
                    }}
                  >
                    Store Details
                  </span>
                </div> */}

                <div style={{ fontSize: "16px", color: "#555" }}>
                  <div style={{ marginBottom: "5px" }}>
                    <span
                      className="fw-semibold"
                      style={{
                        color: theme.palette.blue.dark,
                        marginLeft: "5px", // Add spacing between label and value
                        fontSize: "14px",
                      }}
                    >
                      {storeDetails?.title}
                     
                    </span>
                  </div>

                  <div style={{ marginBottom: "5px" }}>
                    <span
                      className="fw-semibold"
                      style={{
                        color: theme.palette.blue.dark,
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {storeDetails?.email}
                    </span>
                  </div>
                  <div>
                    <span
                      className="fw-semibold text-danger"
                      style={{
                        // color: theme.palette.blue.dark,
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {storeDetails?.stype}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <HubStoreTab />
            </div>
          </>
        )}
      </div>
      {/* MUI Modal for adding a new substore */}
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle>Add New Hub</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {/* <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              margin="normal"
              className="border-0"
            /> */}
            <input
              type="test"
              placeholder="Title"
              className="hoverborder mt-1"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{
                width: "100%", // Make the input take the full width of its parent
                padding: "15px", // Add some padding for better appearance
                borderRadius: "10px", // Add rounded corners
                border: "none", // Light gray border
                marginBottom: "15px", // Space between inputs
                backgroundColor: theme.palette.secondary.lightmain,
              }}
              required
            />
            <input
              type="email"
              placeholder="Email"
              className="hoverborder"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: "100%", // Make the input take the full width of its parent
                padding: "15px", // Add some padding for better appearance
                borderRadius: "10px", // Add rounded corners
                border: "none", // Light gray border
                backgroundColor: theme.palette.secondary.lightmain,
                color: theme.palette.secondary.dark,
              }}
              required
            />
            {/* {responseMessage && (
              <p style={{ color: "green" }}>{responseMessage}</p>
            )} */}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </form>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-sm hovercancel"
            style={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() => setShowModal(false)}
          >
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Cancel
          </button>
          <button
            className="btn btn-sm hoveradd"
            style={{ backgroundColor: theme.palette.blue.dark, color: "white" }}
            onClick={handleSubmit}
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <>
                Adding... <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add
              </>
            )}
          </button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000} // Close after 3 seconds
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "#388e3c", // Change background color to dark green
            color: "white", // Ensure text is readable on dark green
          }}
        >
          We send invitation sent to your email. Please accept it.
        </Alert>
      </Snackbar>
    </div>
  );
}
