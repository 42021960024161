import { faArrowRightLong, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import theme from '../theme';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
    const [width, setWidth] = useState(null);
    const [email, setEmail] = useState(""); // Track password input
    const [error, setError] = useState(""); // Track error messages
    const [loading, setLoading] = useState(false); // Track loading state
    const navigate=useNavigate();
    console.log("base url",process.env.REACT_APP_BASE_URL_LOGIN)

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width === null) {
    return null;
  }
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_LOGIN}auth-email`,
        {
          email: email, // Use email as username
        }
        
      );

      const verifyToken = response.data.results.verifyToken; // Extract accessToken from response
      console.log('loginRes',response.data.results.verifyToken)
      // Save the accessToken in localStorage
      localStorage.setItem("verifyToken", verifyToken);
      // setAccessToken(accessToken);
      // setRefreshToken(refreshToken);
      console.log("login",verifyToken);
      navigate("/otp");
    } catch (error) {
      // Handle error (e.g., incorrect credentials)
      console.error("Login failed:", error);
      setError("Invalid Mail ID"); // Show error message to user
    }finally {
      setLoading(false); // Stop loading after API request is complete
    }
  };
  return (
    <div
    className="container"
    style={{ height: "700px", width: width < 768 ? "95%" : "75%" }}
  >
    <div
      className="row justify-content-center"
      style={{ paddingTop: "150px" }}
    >
      <div
        className="col-md-6 col-sm-12 py-4 mb-2"
        style={{ backgroundColor:'white', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",borderRadius:'10px'}}
      >
       <div className="text-center pb-1">
          <img src='/nego/Icon_NegoBill_logo_V2.png' className='logo'/>
        </div> 
        <div className="text-center pb-1">
          <span className="fw-semibold" style={{fontSize:'20px',color:theme.palette.blue.dark}}>Nego Bill</span>
        </div>
        <div className="text-center">
          <span
            className="fw-semibold "
            style={{ fontSize: "14px", color: theme.palette.blue.dark }}
          >
            Login With Your Email 
          </span>
        </div>
        {error && <div className="text-danger text-center">{error}</div>}{" "}
        {/* Show error if login fails */}
        <form
          onSubmit={handleLogin}
          className="d-flex flex-column align-items-center py-2"
          style={{ width: "70%", margin: "0 auto" }}
        >
          <input
            type="email"
            placeholder="Enter Your Email"
            className="hoverborder"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%", // Make the input take the full width of its parent
              padding: "15px", // Add some padding for better appearance
              borderRadius: "10px", // Add rounded corners
              border: "none", // Light gray border
              marginBottom: "15px", // Space between inputs
              backgroundColor: theme.palette.secondary.lightmain,
              color: theme.palette.secondary.dark,
            }}
            required
          />
          <button
            type="submit"
            className='hoveradd'
            style={{
              width: "100%", // Make the button take the full width of its parent
              padding: "10px", // Add some padding for better appearance
              borderRadius: "10px", // Add rounded corners
              border: "none", // Remove default border
              backgroundColor: theme.palette.blue.dark, // Bootstrap primary color
              color: "white", // Text color
              cursor: "pointer", // Change cursor on hover
              fontSize: "16px", // Increase font size for better readability
            }}
            disabled={loading}
          >
           {loading ? (
                <>
                Signing... <FontAwesomeIcon icon={faSpinner} spin /> &nbsp;
              </>
              ) : (
                <>Sign In <FontAwesomeIcon icon={faArrowRightLong} /></>
              )}
          </button>
        </form>
        {/* <div style={{width:'70%',margin: "0 auto"}}>
            <Divider style={{fontSize:'14px'}}>Or Sign in with</Divider>
            <div className='d-flex justify-content-center py-2'>
            <div className="px-2">
              <button
                className="btn fw-semibold"
                style={{
                  backgroundColor: "#e0e0e0",
                  fontSize: "13px",
                  width: "130px", // Adjust the width as needed
                  display: "flex", // To center the content
                  justifyContent: "center", // Centers the icon and text horizontally
                  alignItems: "center", // Centers the content vertically
                }}
              >
                 <img src="/google.png" style={{width:'15px',marginRight:'10px'}}/>
                &nbsp;Google
              </button>
            </div>
            </div>
        </div> */}
      </div>
    </div>
  </div>
  )
}